import { Lang, TrueFalse, UserSalutation } from "@prisma/client"
import { z } from "zod"

export const userProfileInput = z.object({
  salutation: z.nativeEnum(UserSalutation).optional(),
  title: z.string().optional(),
  firstName: z.string().min(1, { message: "Erforderlich / Required" }),
  lastName: z.string().min(1, { message: "Erforderlich / Required" }),
  dateOfBirth: z.string().min(1, { message: "Erforderlich / Required" }),
  placeOfBirth: z.string().min(1, { message: "Erforderlich / Required" }),
  birthCountry: z.string().min(1, { message: "Erforderlich / Required" }),
  nationality: z.string().min(1, { message: "Erforderlich / Required" }),
})

export const userAddressInput = z.object({
  address1: z.string().min(1, { message: "Erforderlich / Required" }),
  address2: z.string(),
  address3: z.string(),
  city: z.string().min(1, { message: "Erforderlich / Required" }),
  postalCode: z.string().min(1, { message: "Erforderlich / Required" }),
  country: z.string().min(1, { message: "Erforderlich / Required" }),
  latitude: z.number().optional(),
  longitude: z.number().optional(),
})

export const userPartnerSpouseInput = z.object({
  salutation: z.nativeEnum(UserSalutation).optional(),
  title: z.string().optional(),
  email: z.string().email().min(1, { message: "Erforderlich / Required" }),
  firstName: z.string().min(1, { message: "Erforderlich / Required" }),
  lastName: z.string().min(1, { message: "Erforderlich / Required" }),
  dateOfBirth: z.string().min(1, { message: "Erforderlich / Required" }),
  placeOfBirth: z.string().min(1, { message: "Erforderlich / Required" }),
  birthCountry: z.string().min(1, { message: "Erforderlich / Required" }),
  nationality: z.string().min(1, { message: "Erforderlich / Required" }),
})

export const userTaxDetailsInput = z.object({
  taxResidencyCountry: z.string().min(1, { message: "Erforderlich / Required" }),
  taxIdentification: z.string().min(1, { message: "Erforderlich / Required" }),
  usCitizen: z.boolean(),
  noOtherTaxResidency: z.boolean(),
})

export const userAMLInput = z.object({
  occupation: z.string().min(1, { message: "Erforderlich / Required" }),

  sourceFundsCurrentIncome: z.boolean(),
  sourceFundsInheritance: z.boolean(),
  sourceFundsLifeInsurance: z.boolean(),
  sourceFundsSaleOfAssets: z.boolean(),
  sourceFundsSavings: z.boolean(),
  sourceFundsOther: z.string().optional(),

  actOnOwnBehalf: z.nativeEnum(TrueFalse),
  politicallyExposed: z.nativeEnum(TrueFalse),
  lastFiveYearEviction: z.nativeEnum(TrueFalse),
  lastFiveYearForeclosure: z.nativeEnum(TrueFalse),
  lastFiveYearAffidavit: z.nativeEnum(TrueFalse),
  lastFiveYearBankruptcy: z.nativeEnum(TrueFalse),
  criminalRecord: z.nativeEnum(TrueFalse),
})

export const userInvestmentExperienceInput = z.object({
  tokenizedInvestments: z.boolean(),
  bonds: z.boolean(),
  stocks: z.boolean(),
  closedInvestmentFunds: z.boolean(),
  hedgeFunds: z.boolean(),
  certificates: z.boolean(),
  options: z.boolean(),
})

export const userInvestorExperienceInput = z.object({
  AE1: z.string().optional(),
  AE2: z.string().optional(),
  AE3: z.string().optional(),
  AE4: z.string().optional(),
  AE5: z.string().optional(),
  AE6: z.string().optional(),
  AE7: z.string().optional(),
  AE8: z.string().optional(),
  ForceMissedKnowledge: z.nativeEnum(TrueFalse),
})

export const checkSigningStatusInput = z.object({
  envelopeId: z.string().min(1, { message: "Erforderlich / Required" }),
})

export const adminUserCreateInput = z.object({
  email: z.string().email().min(1, { message: "Erforderlich / Required" }),
  initial_password: z.string().min(8).max(32, { message: "Erforderlich / Required" }),
  salutation: z.nativeEnum(UserSalutation),
  firstName: z.string().min(1, { message: "Erforderlich / Required" }),
  lastName: z.string().min(1, { message: "Erforderlich / Required" }),
  propertyId: z.string().min(1, { message: "Erforderlich / Required" }),
  lang: z.nativeEnum(Lang),
})

export type AdminUserInput = z.infer<typeof adminUserCreateInput>

const MYNE_DOMAIN_REGEX = new RegExp("^[A-Za-z0-9._%+-]+@myne-homes.de$")

export const superAdminCreateAdminInput = z.object({
  email: z.string().email().regex(MYNE_DOMAIN_REGEX, { message: "Invalid email" }),
  firstName: z.string().min(1, { message: "Erforderlich / Required" }),
  lastName: z.string().min(1, { message: "Erforderlich / Required" }),
})

export const adminCreatePropertyInput = z.object({
  name: z.string().min(1, { message: "Erforderlich / Required" }),
  shortId: z.string().min(1, { message: "Erforderlich / Required" }),
  address: userAddressInput,
})

export type AdminCreatePropertyInput = z.infer<typeof adminCreatePropertyInput>

export const adminUpdatePropertyInput = adminCreatePropertyInput.extend({
  id: z.string().min(1, { message: "Erforderlich / Required" }),
})
