import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "next-i18next";
import React from "react";

interface Props {
    step: number;
    setStep: (step: number) => void;
    totalSteps: number;
}

export default function MobileSteps ({ step, setStep, totalSteps }: Props) {
  const { t } = useTranslation("common");
  return (
    <div className="text-sm text-black tracking-wide flex w-full opacity-70">
      {(step > 0 && step < totalSteps) && (
        <button onClick={() => setStep(step - 1)} className="flex items-center gap-x-2">
          <ArrowLeftIcon className="h-4 w-4 text-black" />
          <span>{t("form.stepBack")}</span>
        </button>
      )}
      {step < 3 &&
      <div className="ml-auto">
        {t("form.stepPosition", { step: step + 1, totalSteps })}
      </div>
      }
    </div>
  );
}