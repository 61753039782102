/* eslint-disable no-undef */
/// /node_modules/@types/googlemaps/index.d.ts" />
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "next-i18next"
import { FC } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { userTaxDetailsInput } from "server/router/inputs"
import useStore from "store"
import { trpc } from "utils/trpc"

import { ITaxDetails } from "types/common"

import Button from "components/ui/Button"
import { CountrySelect, TextInput, Toggle } from "components/ui/form"

type FormProps = {
  currentStep: number
  setStep: any
}

const UserTaxForm: FC<FormProps> = ({ currentStep, setStep }) => {
  const store = useStore()
  const user = store.authUser
  const utils = trpc.useContext()
  const { t } = useTranslation("common")

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ITaxDetails>({
    resolver: zodResolver(userTaxDetailsInput),
    defaultValues: {
      taxResidencyCountry: user?.regulatoryProfile?.taxDetails?.taxResidencyCountry || "",
      taxIdentification: user?.regulatoryProfile?.taxDetails?.taxIdentification || "",
      usCitizen: user?.regulatoryProfile?.taxDetails?.usCitizen || false,
      noOtherTaxResidency: user?.regulatoryProfile?.taxDetails?.noOtherTaxResidency || false,
    },
  })

  const { isLoading, mutate: updateTaxDetails } = trpc.useMutation(
    [
      user?.regulatoryProfile?.taxDetails
        ? "regulatory.updateTaxDetails"
        : "regulatory.createTaxDetails",
    ],
    {
      onSuccess() {
        utils.invalidateQueries(["user.me"])
        setStep(currentStep + 1)
      },
      onError(error) {
        console.log(error)
        toast.error("Opps! Something went wrong")
      },
    },
  )

  const onSubmitHandler: SubmitHandler<ITaxDetails> = (values) => {
    updateTaxDetails(values)
  }

  const openTaxIdModal = () => {
    store.setTaxIdModalOpen(true)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <CountrySelect
          label={t("form.label.taxResidencyCountry")}
          name="nationality"
          errorMessage={errors.taxResidencyCountry?.message}
          onChange={(v: string) => setValue("taxResidencyCountry", v)}
          value={getValues().taxResidencyCountry}
        />
      </div>
      <div className="col-span-12">
        <TextInput
          label={t("form.label.taxIdentification")}
          errorMessage={errors.taxIdentification?.message}
          {...register("taxIdentification", { required: true })}
          infoText={t("form.taxInfoLabel")}
          infoAction={() => openTaxIdModal()}
        />
      </div>
      <div className="col-span-12">
        <Controller
          name="usCitizen"
          control={control}
          render={({ field }) => (
            <Toggle
              label={t("form.label.usCitizen")}
              // name="usCitizen"
              // onChange={(v: boolean) => setValue("usCitizen", v)}
              // value={getValues().usCitizen}
              {...field}
            />
          )}
        />
      </div>
      <div className="col-span-12">
        <Controller
          name="noOtherTaxResidency"
          control={control}
          render={({ field }) => (
            <Toggle
              label={t("form.label.noOtherTaxResidency")}
              // name="noOtherTaxResidency"
              // onChange={(v: boolean) => setValue("noOtherTaxResidency", v)}
              // value={getValues().noOtherTaxResidency}
              {...field}
            />
          )}
        />
      </div>

      <div className="col-span-12">
        <Button
          variant="primary"
          type="submit"
          className={"mt-2 mx-auto w-full"}
          id="submit"
          loading={isLoading}
        >
          {t("form.continueButton")}
          <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
        </Button>
      </div>
    </form>
  )
}

export default UserTaxForm
