import { Role } from "@prisma/client"
import Layout from "layouts/Layout"
import { AuthAction, withAuthUser } from "next-firebase-auth"
import { useTranslation } from "next-i18next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Link from "next/link"
import React from "react"
import useStore from "store"

import OnboardingSteps from "components/OnboardingSteps"

const Home = () => {
  // const session = useSession()
  const store = useStore()
  const user = store.authUser
  const { t } = useTranslation("common")

  return (
    <Layout>
      {/* {session.status === "authenticated" && user?.role === Role.CUSTOMER ? ( */}
      <div className="bg-light-green min-h-screen w-full pt-24 sm:pt-32">
        <div className="flex h-full justify-center max-w-5xl mx-auto px-4 relative">
          {!!user && user?.role === Role.CUSTOMER && (
            <div className="flex flex-col mt-10 sm:mt-20 max-w-2xl w-full">
              <div className="flex flex-col w-full gap-y-2 mb-6 sm:mb-12">
                <div className="text-2xl sm:text-4xl">
                  {user?.profile
                    ? `${t("mainPage.greetingName", {
                        context: user?.profile?.salutation,
                      })} ${user?.profile?.lastName}!`
                    : `${t("mainPage.greeting")}`}
                </div>

                <div className="text-sm">{t("mainPage.title")}</div>
              </div>
              <OnboardingSteps currentStep={store.onboardingStep} />
            </div>
          )}

          {!!user && user?.role !== Role.CUSTOMER && (
            <div>
              Not a customer. <Link href="/admin">Go to /admin</Link>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const getServerSideProps = async ({ locale }: { locale: any }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"])),
  },
})

export default withAuthUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
})(Home)
