import { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import cn from "classnames";
import Button from "components/ui/Button";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import TaskTitle from "../TaskTitle";
import UserPartnerSpouseForm from "./UserPartnerSpouseForm";
import useStore from "store";
import { useTranslation } from "next-i18next";

interface PartnerSpouseQuestionProps {
  step: number;
  setStep: (value: number) => void;
}

export default function PartnerSpouse ({ step, setStep }: PartnerSpouseQuestionProps) {
  const store = useStore();
  const user = store.authUser;
  const { t } = useTranslation("common");
  const [spouse, setSpouse] = useState<any>(true);
  const [spouseForm, setSpouseForm] = useState(false);

  const spouseOptions = [
    { name: t("form.yes") },
    { name: t("form.no") }
  ];

  useEffect(() => {
    if (user && user.profile && user.profile.partnerSpouse) {
      setSpouse({ name: t("form.yes") });
    }
  }, [t, user]);

  return (
    <>
      {!spouseForm &&
      <>
        <TaskTitle
          title={t("task.userDetails.titlePartner")}
          text={t("task.userDetails.textPartner")}
        />
        <RadioGroup value={spouse} onChange={setSpouse} defaultValue={spouse} className="mt-2">
          <RadioGroup.Label className="sr-only"> Are you married or in a civil partnership? </RadioGroup.Label>
          <div className="grid grid-cols-3 gap-x-12 sm:grid-cols-6">
            {spouseOptions.map((option) => (
              <RadioGroup.Option
                key={option.name}
                value={option}
                className={({ active, checked }) => {
                  if (spouse.name === option.name) {
                    active = true;
                    checked = true;
                  }
                  return (
                    cn(
                      active ? "ring-2 ring-offset-2 ring-brand-dark" : "",
                      checked
                        ? "bg-brand-dark border-transparent text-white hover:bg-brand-dark"
                        : "bg-white border-light-gray text-black hover:bg-light-gray",
                      "border rounded-md py-3 px-8 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer"
                    )
                  );
                }
                }
              >
                <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        <Button
          variant="primary"
          type="button"
          className={"mt-8 mx-auto w-full"}
          id="submit"
          disabled={!spouse}
          onClick={() => {
            if (spouse.name ===  t("form.yes")) {
              setSpouseForm(true);
            } else {
              setStep(step + 1);
            }
          }}
        >
          {t("form.continueButton")}
          <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
        </Button>
      </>
      }

      {spouseForm &&
      <>
        <TaskTitle
          title={t("task.userDetails.titlePartnerForm")}
          text={t("task.userDetails.textPartnerForm")}
        />

        <UserPartnerSpouseForm
          setStep={setStep}
          currentStep={step}
        />
      </>
      }

    </>
  );
}