import { FullScreenModal } from "components/ui/Modal";
import { useState } from "react";
import UserAddressForm from "./UserAddressForm";
import Steps from "../Steps";
import TaskTitle from "../TaskTitle";
import UserProfileForm from "./UserProfileForm";
import PartnerSpouseQuestion from "./PartnerSpouseQuestion";
import TaskSuccess from "../TaskSuccess";
import MobileSteps from "../MobileSteps";
import { useTranslation } from "next-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UserProfileModal = ({ isOpen, onClose }: Props) => {
  const [step, setStep] = useState<number>(0);
  const { t } = useTranslation("common");

  return (
    <FullScreenModal isOpen={isOpen} onClose={onClose}>
      <div className="grid grid-cols-12 min-h-screen">
        <div className="col-span-3 hidden bg-brand-dark text-white min-h-screen sm:flex justify-center items-center">
          <Steps
            steps={[
              { name: t("task.userDetails.step1"), step: 0 },
              { name: t("task.userDetails.step2"), step: 1 },
              { name: t("task.userDetails.step3"), step: 2 }
            ]}
            currentStep={step}
            setStep={setStep}
          />
        </div>

        <div className="col-span-12 sm:col-span-8 md:col-span-9 flex items-center justify-center py-8 sm:pt-0 h-full">
          <div className="flex flex-col p-4 max-w-md w-full">
            {step === 0 && (
              <>
                <TaskTitle
                  title={t("task.userDetails.titlePersonal")}
                  text={t("task.userDetails.textPersonal")}
                />

                <UserProfileForm setStep={setStep} currentStep={step} />
              </>
            )}
            {step === 1 && (
              <>
                <TaskTitle title={t("task.userDetails.titleAddress")} />

                <UserAddressForm setStep={setStep} currentStep={step} />
              </>
            )}

            {step === 2 && (
              <PartnerSpouseQuestion step={step} setStep={setStep} />
            )}

            {step === 3 && (
              <TaskSuccess
                title={t("task.userDetails.success")}
                buttonNextText={t("form.continueButton")}
                onClick={onClose}
              />
            )}

            <div className="mt-4 w-full flex items-center justify-center px-2">
              <MobileSteps step={step} setStep={setStep} totalSteps={3} />
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default UserProfileModal;
